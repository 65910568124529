<template>
  <div>
    <div class="mt-3">
      <a-row :gutter="24">
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Facturas"
            :quantity="invoice_data.total"
            quantity-description="Facturas"
            :percentage="invoice_data.progress"
            :details="invoice_data.details"
            :redirectionTo="invoiceRedirectionObject"
            :loading="loading_obj.invoice"
          />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 16 }">
          <total-details title="Morosidad" />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Nota de crédito"
            :quantity="credit_data.total"
            quantity-description="Solicitudes"
            :percentage="credit_data.progress"
            :details="credit_data.details"
            :redirectionTo="creditRedirectionObject"
            :loading="loading_obj.credit"
          />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Nota de débito"
            :quantity="debit_data.total"
            quantity-description="Solicitudes"
            :percentage="debit_data.progress"
            :details="debit_data.details"
            :redirectionTo="debitRedirectionObject"
            :loading="loading_obj.debit"
          />
        </a-col>
        <a-col class="mb-3" :xs="{ span: 24 }" :lg="{ span: 8 }">
          <detail-card
            title="Ajuste retroactivo"
            :quantity="adjustment_data.total"
            quantity-description="Solicitudes"
            :percentage="adjustment_data.progress"
            :details="adjustment_data.details"
            :redirectionTo="adjustmentRedirectionObject"
            :loading="loading_obj.adjustment"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch, computed } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import CollectionAndBillingDetailsCard from '../collection-and-billing-details-card.vue';
import TotalDetailsShowInfoCard from '../total-details-show-info-card.vue';
import {
  GET_DATE_FILTER_DASHBOARD,
  SELECT_FILTER,
  SELECTED_COMPANY,
  ALL_COMPANIES,
  FETCH_REQUESTS_SUMMARY,
  GET_REQUESTS_SUMMARY,
  FETCH_INVOICE_SUMMARY,
  GET_INVOICE_SUMMARY
} from '@/store/types';
import notification from '@/utils/notifications';

export default {
  components: {
    'detail-card': CollectionAndBillingDetailsCard,
    'total-details': TotalDetailsShowInfoCard
  },
  setup() {
    const store = useStore();

    const percentageCalculation = (total, number) => {
      if (total != 0 && number != 0) {
        return Math.round((number * 100) / total);
      }
      return 0;
    };

    const selected_companies = computed(() => {
      const selected_holding = store.getters[SELECT_FILTER].map((value) =>
        parseInt(value)
      );
      const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
        parseInt(value)
      );
      const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
        parseInt(value)
      );

      if (selected_holding.length > 0) {
        if (selected_comp.length > 0) {
          return selected_comp;
        }
        return all_companies;
      }

      if (
        selected_comp.length > 0 &&
        selected_comp.length < all_companies.length
      ) {
        return selected_comp;
      }

      return [];
    });

    const selected_period = computed(
      () => store.getters[GET_DATE_FILTER_DASHBOARD]
    );

    const loading_obj = reactive({});
    const getCardInfo = async (url, params, name) => {
      loading_obj[name] = true;
      try {
        await store.dispatch(url, params);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loading_obj[name] = false;
    };

    watch(
      () => selected_companies.value,
      (new_values) => {
        if (new_values) {
          getMainData();
        }
      }
    );

    watch(
      () => selected_period.value,
      () => {
        getMainData();
      }
    );

    // * Obtencion de data
    const invoice_data = computed(() => {
      const { progress, total, issued, notIssued, paid } =
        store.getters[GET_INVOICE_SUMMARY];

      return {
        progress: Math.round(progress),
        total,
        details: [
          {
            id: 3,
            description: 'Emitida',
            total: percentageCalculation(total, issued),
            state: { invoiceState: 'ISSUED' }
          },
          {
            id: 4,
            description: 'Sim emitir',
            total: percentageCalculation(total, notIssued),
            state: { invoiceState: 'NOT_ISSUED' }
          },
          {
            id: 5,
            description: 'Pagadas',
            total: percentageCalculation(total, paid),
            state: { invoiceState: 'PAID' }
          }
        ]
      };
    });

    const invoiceRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'CobranzaFacturacion',
        query: obj
      };
    });

    const getRequestsData = (values) => {
      const { progress, total, inInsurance, approved, rejected, canceled } =
        values;

      return {
        progress: Math.round(progress),
        total,
        details: [
          {
            id: 1,
            description: 'En aseguradora',
            total: percentageCalculation(total, inInsurance),
            state: { states: 'IN_INSURANCE' }
          },
          {
            id: 2,
            description: 'Aprobados',
            total: percentageCalculation(total, approved),
            state: { states: 'APPROVED' }
          },
          {
            id: 3,
            description: 'Rechazados',
            total: percentageCalculation(total, rejected),
            state: { states: 'REJECTED' }
          },
          {
            id: 4,
            description: 'Cancelados',
            total: percentageCalculation(total, canceled),
            state: { states: 'CANCELED' }
          }
        ]
      };
    };

    const credit_data = computed(() => {
      const values = store.getters[GET_REQUESTS_SUMMARY]('CREDIT_NOTE_REQUEST');
      if (values != undefined) {
        return getRequestsData(values);
      }
      return {};
    });

    const creditRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'CreditNote',
        query: obj
      };
    });

    const debit_data = computed(() => {
      const values = store.getters[GET_REQUESTS_SUMMARY]('DEBIT_NOTE_REQUEST');
      if (values != undefined) {
        return getRequestsData(values);
      }
      return {};
    });

    const debitRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'DebitNote',
        query: obj
      };
    });

    const adjustment_data = computed(() => {
      const values = store.getters[GET_REQUESTS_SUMMARY](
        'RETROACTIVE_ADJUSTMENT_REQUEST'
      );
      if (values != undefined) {
        return getRequestsData(values);
      }
      return {};
    });

    const adjustmentRedirectionObject = computed(() => {
      const obj = getObjectParams.value;

      return {
        name: 'RetroActiveAdjustment',
        query: obj
      };
    });

    const getObjectParams = computed(() => {
      const obj = {};

      if (selected_companies.value.length != 0) {
        obj['companyId'] = selected_companies.value.join(',');
      }

      if (selected_period.value) {
        obj['period'] = moment(selected_period.value, 'YYYY-MM').format(
          'YYYY-MM'
        );
      }

      return obj;
    });

    const getMainData = () => {
      const payload = getObjectParams.value;
      let params = '';

      Object.keys(payload).forEach((key, index) => {
        if (index === 0) {
          params += `?${key}=${payload[key]}`;
        } else {
          params += `&${key}=${payload[key]}`;
        }
      });

      getCardInfo(FETCH_INVOICE_SUMMARY, params, 'invoice');

      params = '';
      Object.keys(payload).forEach((key) => {
        params += `&${key}=${payload[key]}`;
      });

      getCardInfo(
        FETCH_REQUESTS_SUMMARY,
        { type: 'CREDIT_NOTE_REQUEST', payload: params },
        'credit'
      );
      getCardInfo(
        FETCH_REQUESTS_SUMMARY,
        { type: 'DEBIT_NOTE_REQUEST', payload: params },
        'debit'
      );
      getCardInfo(
        FETCH_REQUESTS_SUMMARY,
        { type: 'RETROACTIVE_ADJUSTMENT_REQUEST', payload: params },
        'adjustment'
      );
    };

    onMounted(() => {
      getMainData();
    });

    return {
      credit_data,
      debit_data,
      adjustment_data,
      loading_obj,
      creditRedirectionObject,
      debitRedirectionObject,
      adjustmentRedirectionObject,
      invoice_data,
      invoiceRedirectionObject
    };
  }
};
</script>
